import React from 'react';

import SignUpBanner from '../components/Banners/SignUp';
import { Layout, Pagination } from '../components/common';
import { MetaData } from '../components/common/meta';
import { TrendingPostGrid } from '../components/Post/Grid/Trending';
import PostsIcon from '../images/Posts';

import style from '../styles/pages/trending.scss';

import { graphql } from 'gatsby';

interface ITrendingProps {
  data: {
    allGhostPost: {
      totalCount: number;
      edges: [
        {
          node: {
            post: any;
          };
        }
      ];
    };
    allGhostPage: {
      totalCount: number;
      edges: [
        {
          node: {
            post: any;
          };
        }
      ];
    };
  };
  location: {
    pathname: string;
  };
  pageContext: any;
}

/**
 * Trending page
 *
 * Loads all trending posts from popular-posts.json.
 *
 */
const TrendingPage = ({ location, data, pageContext }: ITrendingProps) => {
  const posts = data.allGhostPost.edges;
  const pages = data.allGhostPage.edges;

  const allPostsAndPages = posts.concat(pages);

  return (
    <>
      <MetaData
        location={location}
        data={{}}
        title="Popular Personal Finance Articles from Think Save Retire"
        description="Read the articles on Think Save Retire that have helped the most people achieve financial independence and goals such as retiring by 40."
      />
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div className={style.hero}>
          <div className="container">
            <h1>Trending</h1>
            <p>
              <PostsIcon /> {data.allGhostPost.totalCount + data.allGhostPage.totalCount}{' '}
              {data.allGhostPost.totalCount + data.allGhostPage.totalCount === 1 ? 'post' : 'posts'}
            </p>
          </div>
        </div>
        <div className={style.posts}>
          <div className="container">
            <TrendingPostGrid posts={allPostsAndPages} />
            <Pagination className={style.pagination} pageContext={pageContext} />
          </div>
        </div>
        <SignUpBanner />
      </Layout>
    </>
  );
};

export default TrendingPage;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      filter: { featured: { eq: true } }
    ) {
      totalCount
      edges {
        node {
          slug
          feature_image
          featured
          excerpt
          title
          published_at
          primary_author {
            name
            profile_image
          }
          html
          slug
          tags {
            name
            slug
          }
        }
      }
      totalCount
    }
    allGhostPage(
      sort: { order: DESC, fields: [published_at] }
      filter: { featured: { eq: true } }
    ) {
      totalCount
      edges {
        node {
          slug
          feature_image
          featured
          excerpt
          title
          published_at
          primary_author {
            name
            profile_image
          }
          html
          slug
          tags {
            name
            slug
          }
        }
      }
      totalCount
    }
  }
`;
